// import ReactGA from 'react-ga'
import { AuthProvider } from 'react-admin';

import { Permissions } from '../Types/types';

const authProvider: AuthProvider = {
  login: ({ email, password, code, token }) => {
    const request = new Request(import.meta.env.VITE_API_LOGIN_2FA_URL, {
      method: 'POST',
      body: JSON.stringify({ code, email, password, token }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          clearLocalStorage();
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ access_token, username }) => {
        localStorage.setItem('auth', 'true');
        localStorage.setItem('roles', JSON.stringify(username.user_roles));
        localStorage.setItem(
          'permissions',
          JSON.stringify(username.user_permissions),
        );
        localStorage.setItem('token', access_token);
        localStorage.setItem('name', username.name);
        localStorage.setItem('email', username.email);

        // const user = data.users.find(
        //     (u) => u.username === username && u.password === password
        // );

        // let { password, ...userToPersist } = username;
        localStorage.setItem(
          'user',
          JSON.stringify({
            ...username,
            ...{ fullName: username.name },
          }),
        );

        // ReactGA.set({
        //     userId: username.email,
        // })

        return { username: username.name };
      });
  },

  logout: () => {
    if (localStorage.getItem('token')) {
      const request = new Request(import.meta.env.VITE_API_LOGOUT_URL, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        }),
      });

      return fetch(request).then(() => {
        localStorage.removeItem('auth');
        localStorage.removeItem('twoFaQrCode');
        clearLocalStorage();
        return Promise.resolve();
      });
    }

    localStorage.removeItem('auth');
    clearLocalStorage();
    return Promise.resolve();
  },

  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');

      clearLocalStorage();
      return Promise.reject();
    }

    return Promise.resolve();
  },

  checkAuth: () => {
    if (localStorage.getItem('2fa') === 'true') {
      return Promise.reject({
        message: 'Enter the code',
        redirect_uri: false,
        redirectTo: false,
      });
    }

    if (!localStorage.getItem('auth') || !localStorage.getItem('token')) {
      clearLocalStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: (): Promise<Permissions> => {
    const roles = JSON.parse(localStorage.getItem('roles') || '[]') as string[];

    const permissions = JSON.parse(
      localStorage.getItem('permissions') || '[]',
    ) as string[];

    if (!permissions) {
      return Promise.reject();
    }

    return Promise.resolve({
      permissions: permissions,
      roles: roles,
      hasPermission: (permission: string) => {
        return permissions ? permissions.includes(permission) : null;
      },
      hasRole: (role: string) => {
        return roles ? roles.includes(role) : null;
      },
    });
  },

  getIdentity: () => {
    const persistedUser = localStorage.getItem('user');
    const user = persistedUser ? JSON.parse(persistedUser) : null;

    return Promise.resolve(user);
  },
};

function clearLocalStorage() {
  localStorage.clear();
}

export default authProvider;
