import { useState } from 'react';
import { sleep } from '../Helpers/Helpers';

const API_URL: string = import.meta.env.VITE_API_URL as string;

type FetchResult<T> = {
  data?: T;
  loading: boolean;
  error?: Error | boolean | unknown;
};

export const useFetchGet = <T>(path: string, userOptions = {}) => {
  const [data, setData] = useState<T | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | boolean | unknown>();

  async function fetchUrl() {
    try {
      setLoading(true);
      const response = await apiGet<T>(path, userOptions);
      setData(response as T); // Type assertion here
      setLoading(false);
    } catch (error) {
      setError(error);
    }
  }

  return [fetchUrl, { data, loading, error }] as [() => Promise<void>, FetchResult<T>];
};



export const useFetchPost = (path: string, body: ApiPostBody, userOptions = {}) => {

  const [data, setData] = useState<Response>();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<Error | boolean | unknown>();


  async function fetchUrl() {
    setError(false)
    try {
      setLoading(true);
      const response = await apiPost(path, body, userOptions);
      setData(response);
      setError(false)
      setLoading(false);

    } catch (error) {
      setError(error);
    }
  }

  return [fetchUrl, { data, loading, error }];
}

// export const ApiGet = async <T>(path: string, userOptions = {}): Promise<T> => {
//   const result = await fetchResource(API_URL + path, userOptions);
//   return result;
// };

export const REQUEST_FAILED = 'REQUEST_FAILED';

export const api = (path: string, userOptions = {}): Promise<Response> =>
  fetchResource(API_URL + path, userOptions);
export const apiGet = <T>(path: string, userOptions = {}): Promise<T> =>
  fetchResource(API_URL + path, userOptions);

export type ApiPostBody = object | null | undefined | string | File;


export const apiPost = (
  path: string,
  body: ApiPostBody,
  userOptions = {},
): Promise<Response> => {
  return fetchResource(API_URL + path, {
    ...{
      method: 'POST',
      body: body,
    },
    ...userOptions,
  });
};

export const apiGetWithRetry = async (
  path: string,
  userOptions = {},
  n = 5,
  all = false,
  pause = 4000,
): Promise<Response> => {
  return retryRequest(() => apiGet(path, userOptions), n, all, pause);
};
export const apiPostWithRetry = async (
  path: string,
  body: ApiPostBody,
  userOptions = {},
  n = 5,
  all = false,
  pause = 4000,
): Promise<Response> => {
  return retryRequest(() => apiPost(path, body, userOptions), n, all, pause);
};

export const retryRequest = async (
  requestToRetry: () => Promise<Response>,
  n = 5,
  all = false,
  pause = 4000,
): Promise<Response> => {

  let error: Error | string | object | IApiError | unknown;

  for (let i = 0; i < n; i++) {
    try {
      return await sleep(i === 0 ? 0 : i * pause).then(() => {
        return requestToRetry();
      });
    } catch (err) {
      if (!(err as IApiError).isNetworkFailure() && !all) {
        throw err;
      }
      error = err
    }
  }

  throw error;
};

export interface IApiError {
  response: string | null | Response | object | IApiErrorResponse;
  status: string | number;
  toString(): string;
  isNetworkFailure(): boolean;
}

type IApiErrorResponse = {
  error?: string;
}

class ApiError extends Error implements IApiError {
  response: string | null;
  status: string;

  constructor(message: string, data: string | null, status: string) {
    super(message);
    let response: string | null = null;


    try {
      response = JSON.parse(data || '');

    } catch (e) {
      response = data;
    }

    this.response = response;
    this.status = status;
  }

  toString() {
    return `${this.message}\nResponse:\n${this.response ? JSON.stringify(this.response, null, 2) : this.response}`;
  }

  isNetworkFailure() {
    return this.status === REQUEST_FAILED;
  }
}


interface FetchResourceOptions {
  body?: object | null | undefined | string | File | BodyInit;
  cache?: RequestCache;
  credentials?: RequestCredentials;
  headers?: HeadersInit;
  integrity?: string;
  keepalive?: boolean;
  method?: string;
  mode?: RequestMode;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  signal?: AbortSignal | null;
  window?: null;
}

export const fetchResource = async <T>(path: string, userOptions: FetchResourceOptions): Promise<T> => {
  const defaultOptions = {};
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  };

  const options = {
    ...defaultOptions,
    ...userOptions,
    headers: {
      ...defaultHeaders,
      ...userOptions.headers,
    },
  };

  const url = path;

  const { body, ...requestWithoutBody } = options;

  const isFile = body instanceof File;

  const request: RequestInit = {
    ...requestWithoutBody,
  }

  if (options.body && typeof options.body === 'object' && !isFile) {
    request.body = JSON.stringify(body);
  } else if (isFile) {
    request.body = body;
  }

  let response: Response | null = null;

  try {
    response = await fetch(url, request);

    if (response.status < 200 || response.status >= 300) {
      const parsedResponse = await response.text();
      throw parsedResponse;
    }

    const parsedResponse = await response.json();

    return parsedResponse;

  } catch (error) {

    const er = JSON.parse(typeof error === 'string' ? error : '')?.error || 'Unknown error';

    if (response) {
      let err;

      if (response.statusText) {
        err = new ApiError(
          er || response.statusText,
          typeof error === 'string' && error.toString() || 'Undefined error',
          response.status.toString(),
        );
      } else {
        err = new ApiError(
          er || `Request failed with status ${response.status}.`,
          typeof error === 'string' && error.toString() || 'Undefined error',
          response.status.toString(),
        );
      }

      throw err;
    } else {
      const err = new ApiError(typeof error === 'string' && error.toString() || 'Undefined error', null, REQUEST_FAILED);
      throw err;
    }
  }
};

export default fetchResource;
