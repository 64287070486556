import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CoffeeIcon from '@mui/icons-material/Coffee';
import CreditCard from '@mui/icons-material/CreditCard';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import FactCheck from '@mui/icons-material/FactCheck';
import Favorite from '@mui/icons-material/Favorite';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import KeyIcon from '@mui/icons-material/Key';
import LabelOutlined from '@mui/icons-material/LabelOutlined';
// import Favorite from "@mui/icons-material/Favorite";
// import GroupWork from "@mui/icons-material/GroupWork";
// import LabelOutlined from "@mui/icons-material/LabelOutlined";
// import List from '@mui/icons-material/List';
import LocalActivity from '@mui/icons-material/LocalActivity';
import PaymentsOutlined from '@mui/icons-material/PaymentsOutlined';
import People from '@mui/icons-material/People';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import RequestQuote from '@mui/icons-material/RequestQuote';
import School from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Star from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import TextureIcon from '@mui/icons-material/Texture';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
// import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import WebhookIcon from '@mui/icons-material/Webhook';
import { ReactElement, ReactNode } from 'react';

// import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
// import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
// import People from "@mui/icons-material/People";
// import QuestionAnswer from "@mui/icons-material/QuestionAnswer";
// import School from "@mui/icons-material/School";
// import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
// import Star from "@mui/icons-material/Star";
// import StarBorder from "@mui/icons-material/StarBorder";
// import CreditCard from "@mui/icons-material/CreditCard";

// import VisibilityIcon from "@mui/icons-material/Visibility";
// import LocalActivity from "@mui/icons-material/LocalActivity";
/**
 * name: CamelCase - it will be lowercased for resource name, and camelcased for components fetching
 * type: 'resource',
 * permission: 'default',
 */

export interface MenuPropItem {
  type: 'resource' | 'static' | 'dynamic' | 'group' | 'line';
  name: string;
  icon?: ReactElement | ReactNode;
  label?: string;
  permission?: 'default' | string | boolean;
  to?: string;
  hide?: boolean;
}

export interface MenuProp extends MenuPropItem {
  resources?: MenuPropItem[];
}

const menuList: MenuProp[] = [
  {
    type: 'resource',
    name: 'children',
    permission: 'default',
    icon: <Favorite color="child" />,
    label: 'Children',
  },
  {
    type: 'resource',
    name: 'invoices',
    permission: 'default',
    icon: <AttachMoney color="finance" />,
    label: 'Invoices',
  },
  {
    type: 'resource',
    name: 'humans',
    permission: 'default',
    icon: <People color="human" />,
    label: 'Humans',
  },

  {
    type: 'line',
    name: 'line',
  },

  {
    type: 'group',
    label: 'School',
    name: 'School',
    icon: <School color="school" />,
    resources: [
      {
        type: 'resource',
        name: 'schools',
        permission: 'default',
        icon: <School color="school" />,
        label: 'Schools',
      },
      {
        type: 'resource',
        name: 'schoolProgrammes',
        permission: 'default',
        icon: <SchoolOutlinedIcon color="school" />,
        label: 'School programmes',
      },
    ],
  },

  {
    type: 'group',
    label: 'Finance',
    name: 'finance',
    icon: <AttachMoney color="finance" />,
    resources: [
      {
        name: 'FinanceSummary',
        type: 'dynamic',
        permission: 'x_finance_summary',
        icon: <AttachMoney color="finance" />,
        label: 'Finance Summary',
        to: 'finance/summary',
      },
      {
        name: 'scholarships',
        type: 'resource',
        permission: 'default',
        icon: <Star color="child" />,
        label: 'Scholarships',
      },

      {
        name: 'sponsorships',
        type: 'resource',
        permission: 'default',
        icon: <StarBorder color="child" />,
        label: 'Sponsorships',
      },

      {
        name: 'PaymentProfiles',
        type: 'resource',
        permission: 'default',
        icon: <PaymentsOutlined color="finance" />,
        label: 'Payment profiles',
      },

      {
        type: 'resource',
        name: 'ersteReports',
        icon: <RequestQuote color="erste" />,
        permission: 'default',
        label: 'Erste reports',
      },
    ],
  },

  {
    name: 'donationProjects',
    type: 'resource',
    permission: 'default',
    icon: <LocalActivity color="project" />,
    label: 'Donation Projects',
  },

  {
    type: 'group',
    label: 'Administration',
    name: 'administration',
    icon: <SettingsApplicationsIcon />,
    resources: [
      {
        type: 'resource',
        name: 'tags',
        icon: <LabelOutlined />,
        permission: 'x_tags_menu',
        label: 'Tags',
      },
    ],
  },
  {
    type: 'group',
    label: 'Logs',
    name: 'logs',
    icon: <FormatListNumberedIcon />,
    resources: [
      {
        name: 'ersteReportLogs',
        type: 'resource',
        permission: 'default',
        icon: <FactCheck />,
        label: 'Erste report logs',
      },
    ],
  },
  {
    type: 'group',
    label: 'User Access',
    name: 'access',
    icon: <AdminPanelSettingsIcon />,
    resources: [
      {
        type: 'resource',
        name: 'users',
        icon: <PeopleAlt />,
        permission: 'x_users_menu',
        label: 'Users',
      },
      {
        name: 'roles',
        type: 'resource',
        permission: 'default',
        icon: <KeyIcon />,
        label: 'Roles',
      },
      {
        name: 'permissions',
        type: 'resource',
        permission: 'default',
        icon: <VpnKeyOutlinedIcon />,
        label: 'Permissions',
      },
    ],
  },
  {
    type: 'static',
    name: 'coffee',
    icon: <CoffeeIcon />,
    label: 'Coffee',
    permission: false,
  },
  {
    type: 'dynamic',
    permission: 'invoices_create',
    to: '/invoices/createInvoice',
    name: 'CreateInvoice',
    hide: true,
  },
  {
    type: 'dynamic',
    permission: false,
    to: '/me',
    name: 'MyProfile',
    hide: true,
  },

  {
    type: 'group',
    label: 'Stripe',
    name: 'stripe',
    icon: <CreditCard sx={{ color: '#635bff' }} />,

    resources: [
      {
        name: 'stripeInvoices',
        type: 'resource',
        permission: 'default',
        icon: <TextureIcon />,
        label: 'Invoices',
      },
      {
        name: 'stripeSubscriptions',
        type: 'resource',
        permission: 'default',
        icon: <TextureIcon />,
        label: 'Subscriptions',
      },
      {
        name: 'stripePaymentIntents',
        type: 'resource',
        permission: 'default',
        icon: <TextureIcon />,
        label: 'Payment Intents',
      },
      {
        name: 'stripeCharges',
        type: 'resource',
        permission: 'default',
        icon: <CreditScoreIcon sx={{ color: '#635bff' }} />,
        label: 'Charge',
      },
      {
        name: 'stripeProducts',
        type: 'resource',
        permission: 'default',
        icon: <TextureIcon />,
        label: 'Products',
      },
      {
        name: 'stripePrices',
        type: 'resource',
        permission: 'default',
        icon: <TextureIcon />,
        label: 'Prices',
      },
      {
        name: 'stripeBalanceTransactions',
        type: 'resource',
        permission: 'default',
        icon: <TextureIcon />,
        label: 'Balance Transactions',
      },
      {
        name: 'stripePayouts',
        type: 'resource',
        permission: 'default',
        icon: <TextureIcon />,
        label: 'Payouts',
      },
      {
        name: 'stripeWebhooks',
        type: 'resource',
        permission: 'default',
        icon: <WebhookIcon sx={{ color: '#635bff' }} />,
        label: 'Stripe Webhooks',
      },
      {
        name: 'wiseWebhooks',
        type: 'resource',
        permission: 'default',
        icon: <WebhookIcon sx={{ color: '#163300' }} />,
        label: 'Wise Webhooks',
      },
    ],
  },
];

export default menuList;
