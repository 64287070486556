import { css } from '@emotion/react';
import { CardActions } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { ReactElement, memo, useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  FormProvider,
  UseFormReturn,
} from 'react-hook-form';

import { useFetchGet } from '../DataProvider/fetchResource';
import { FormInputText } from './FormInputText';

const classes = {
  form: css`
    padding: 0 1em 1em 1em;
    display: grid;
    grid-row-gap: 20px;
    padding: 20px;
    margin: 10px 40px;
  `,
  input: css`
    margin-top: 1em;
  `,
  button: css`
    width: 100%;
  `,
  icon: () => css`
    margin-right: 8px;
  `,
  warningMessage: css`
    /* width: 400px; */
    padding: 20px;
  `,
};

interface Props {
  control: Control<FieldValues> | undefined;
  AuthSaveButton: ReactElement;
  methods: FieldValues | undefined;
}

interface Response {
  qr: string;
  token: string;
}

export const Create2FaForm = memo(
  ({ control, AuthSaveButton, methods }: Props) => {
    const [twoFaQrCode, setTwoFaQrCode] = useState<Response>();

    const [
      get2FaQrCode,
      { data: twofaQrCodeData, loading: loadingTwoFa, error: errorTwofa },
    ] = useFetchGet<Response>('/2fa/create');

    const [lo, setLo] = useState(false);

    const getQrCode = () => {
      if (!twoFaQrCode && !loadingTwoFa && !errorTwofa && !lo) {
        setLo(true);
        get2FaQrCode();
      }
    };

    useEffect(() => {
      setTwoFaQrCode(twofaQrCodeData);
      localStorage.setItem('twoFaQrCode', twofaQrCodeData?.token ?? '');
    }, [twofaQrCodeData]);

    return (
      <FormProvider {...(methods as UseFormReturn<FieldValues>)}>
        <form noValidate>
          <div css={classes.form}>
            <FormInputText
              autoFocus={true}
              defaultValue={''}
              name="code"
              label="Code"
              control={control}
              required
              rules={{
                required: true,
              }}
            />
          </div>

          <CardActions>{AuthSaveButton}</CardActions>
        </form>
        <div css={classes.warningMessage}>
          <p>
            <strong>Setup 2FA</strong>
          </p>

          <Grid
            container
            spacing={3}
            sx={{ maxWidth: '90vw', overflow: 'scroll', maxHeight: '50vh' }}
          >
            <Grid item md={8}>
              <p>
                It is recommended to perform this step on a laptop or desktop
                computer for easier QR code scanning.
              </p>
              <h2>1.</h2>
              <div>
                <ul>
                  <li> Install Google Authenticator on your Mobile Device:</li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                      Play Store (Android)
                    </a>
                  </li>
                  <li>
                    {' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                    >
                      App Store (iPhone)
                    </a>
                  </li>
                </ul>
              </div>

              <h2>2.</h2>
              <div>
                <ul>
                  <li>Open the app on your mobile device.</li>
                  <li>
                    On this page, click the button on the right side that says{' '}
                    {`"Click to get QR Code"`} to obtain the QR code.
                  </li>
                  <li>
                    On your phone, tap the plus sign icon to add a new QR code.
                  </li>
                  <li>Select {`"Scan a QR code."`} </li>
                  <li>
                    Use your phone to scan the QR code on this page. Ensure that
                    the QR code displayed on the right side is properly aligned
                    within the scanner box on your phone.
                  </li>
                </ul>
              </div>
              <h2>3.</h2>
              <div>
                <ul>
                  <li>
                    After a successful scan, a new entry titled{' '}
                    {`"Krug ljubavi (your email)"`} will appear at the bottom of
                    the list.
                  </li>
                  <li>
                    This entry contains a six-digit code that changes
                    periodically.
                  </li>
                </ul>
              </div>
              <h2>4.</h2>
              <div>
                <ul>
                  <li>
                    Enter the code from your mobile device into the provided
                    input box. Make sure to enter the code before it expires, as
                    the numbers change quickly.
                  </li>
                  <li>Click the Verify Code button.</li>
                </ul>
              </div>
              <div>
                <h2>5.</h2>

                <ul>
                  <li>
                    After successfully saving the changes, you will be
                    redirected to the login screen.
                  </li>
                  <li>Log in using your email and password as usual.</li>
                  <li>
                    Once logged in, provide the six-digit code from the Google
                    Authenticator app on your phone.
                  </li>
                  <li>
                    After successful verification, you will be fully logged into
                    your account with enhanced security.
                  </li>
                </ul>
              </div>
            </Grid>

            <Grid item md={4}>
              <Button onClick={getQrCode} disabled={lo}>
                Click to get QR Code
              </Button>
              <h3>QR code</h3>
              {twoFaQrCode && twoFaQrCode.qr && (
                <img src={twoFaQrCode.qr} alt="Google authenticator QR code" />
              )}
            </Grid>
          </Grid>
        </div>
      </FormProvider>
    );
  },
);

Create2FaForm.displayName = 'Create2FaForm';
