import { css } from '@emotion/react';
import { CardActions } from '@mui/material';
import { ReactElement } from 'react';
import {
  Control,
  FieldValues,
  FormProvider,
  UseFormReturn,
} from 'react-hook-form';

import { FormInputText } from './FormInputText';

const classes = {
  form: css`
    padding: 0 1em 1em 1em;
    display: grid;
    grid-row-gap: 20px;
    padding: 20px;
    margin: 10px 40px;
  `,
  input: css`
    margin-top: 1em;
  `,
  button: css`
    width: 100%;
  `,
  icon: () => css`
    margin-right: 8px;
  `,
};

interface Props {
  control: Control<FieldValues> | undefined;
  AuthSaveButton: ReactElement;
  methods: FieldValues | undefined;
}

export const LoginFormUsername = ({
  control,
  AuthSaveButton,
  methods,
}: Props) => {
  return (
    <FormProvider {...(methods as UseFormReturn<FieldValues>)}>
      <form noValidate>
        <div css={classes.form}>
          <FormInputText
            autoFocus={true}
            defaultValue={''}
            name="email"
            label="E-mail"
            control={control}
            required
            rules={{
              required: true,
              pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: 'Email is required',
              },
            }}
            type="email"
            autocomplete="on"
          />
          <FormInputText
            defaultValue={''}
            name="password"
            label="Password"
            control={control}
            required
            rules={{
              required: {
                value: true,
                message: 'Password is required',
              },
            }}
            type="password"
            autocomplete="on"
          />
        </div>

        <CardActions>
          {AuthSaveButton}
          {/* <Save
            props={props}
            loading={loading}
            setLoading={setLoading}
            twoFa={twoFa}
            setTwoFa={setTwoFa}
          /> */}
        </CardActions>
      </form>
    </FormProvider>
  );
};
