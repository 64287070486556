import React, { lazy } from 'react';

export type DynamicComponent = React.FC | React.ComponentType<unknown>;

export const ChildrenCreate: DynamicComponent = lazy(() => import("./Resources/Children/ChildrenCreate"));
export const ChildrenEdit: DynamicComponent = lazy(() => import("./Resources/Children/ChildrenEdit"));
export const ChildrenList: DynamicComponent = lazy(() => import("./Resources/Children/ChildrenList"));
export const ChildrenShow: DynamicComponent = lazy(() => import("./Resources/Children/ChildrenShow"));
export const DonationProjectsCreate: DynamicComponent = lazy(() => import("./Resources/DonationProjects/DonationProjectsCreate"));
export const DonationProjectsEdit: DynamicComponent = lazy(() => import("./Resources/DonationProjects/DonationProjectsEdit"));
export const DonationProjectsList: DynamicComponent = lazy(() => import("./Resources/DonationProjects/DonationProjectsList"));
export const DonationProjectsShow: DynamicComponent = lazy(() => import("./Resources/DonationProjects/DonationProjectsShow"));
export const ErsteReportLogsList: DynamicComponent = lazy(() => import("./Resources/ErsteReportLogs/ErsteReportLogsList"));
export const ErsteReportLogsShow: DynamicComponent = lazy(() => import("./Resources/ErsteReportLogs/ErsteReportLogsShow"));
export const ErsteReportsList: DynamicComponent = lazy(() => import("./Resources/ErsteReports/ErsteReportsList"));
export const ErsteReportsShow: DynamicComponent = lazy(() => import("./Resources/ErsteReports/ErsteReportsShow"));
export const HumansCreate: DynamicComponent = lazy(() => import("./Resources/Humans/HumansCreate"));
export const HumansEdit: DynamicComponent = lazy(() => import("./Resources/Humans/HumansEdit"));
export const HumansList: DynamicComponent = lazy(() => import("./Resources/Humans/HumansList"));
export const HumansShow: DynamicComponent = lazy(() => import("./Resources/Humans/HumansShow"));
export const InvoicesEdit: DynamicComponent = lazy(() => import("./Resources/Invoices/InvoicesEdit"));
export const InvoicesList: DynamicComponent = lazy(() => import("./Resources/Invoices/InvoicesList"));
export const InvoicesShow: DynamicComponent = lazy(() => import("./Resources/Invoices/InvoicesShow"));
export const PaymentProfilesEdit: DynamicComponent = lazy(() => import("./Resources/PaymentProfiles/PaymentProfilesEdit"));
export const PaymentProfilesList: DynamicComponent = lazy(() => import("./Resources/PaymentProfiles/PaymentProfilesList"));
export const PaymentProfilesShow: DynamicComponent = lazy(() => import("./Resources/PaymentProfiles/PaymentProfilesShow"));
export const PermissionsCreate: DynamicComponent = lazy(() => import("./Resources/Permissions/PermissionsCreate"));
export const PermissionsEdit: DynamicComponent = lazy(() => import("./Resources/Permissions/PermissionsEdit"));
export const PermissionsList: DynamicComponent = lazy(() => import("./Resources/Permissions/PermissionsList"));
export const PermissionsShow: DynamicComponent = lazy(() => import("./Resources/Permissions/PermissionsShow"));
export const RolesCreate: DynamicComponent = lazy(() => import("./Resources/Roles/RolesCreate"));
export const RolesEdit: DynamicComponent = lazy(() => import("./Resources/Roles/RolesEdit"));
export const RolesList: DynamicComponent = lazy(() => import("./Resources/Roles/RolesList"));
export const RolesShow: DynamicComponent = lazy(() => import("./Resources/Roles/RolesShow"));
export const ScholarshipsEdit: DynamicComponent = lazy(() => import("./Resources/Scholarships/ScholarshipsEdit"));
export const ScholarshipsList: DynamicComponent = lazy(() => import("./Resources/Scholarships/ScholarshipsList"));
export const ScholarshipsShow: DynamicComponent = lazy(() => import("./Resources/Scholarships/ScholarshipsShow"));
export const SchoolProgrammesCreate: DynamicComponent = lazy(() => import("./Resources/SchoolProgrammes/SchoolProgrammesCreate"));
export const SchoolProgrammesEdit: DynamicComponent = lazy(() => import("./Resources/SchoolProgrammes/SchoolProgrammesEdit"));
export const SchoolProgrammesList: DynamicComponent = lazy(() => import("./Resources/SchoolProgrammes/SchoolProgrammesList"));
export const SchoolProgrammesShow: DynamicComponent = lazy(() => import("./Resources/SchoolProgrammes/SchoolProgrammesShow"));
export const SchoolsCreate: DynamicComponent = lazy(() => import("./Resources/Schools/SchoolsCreate"));
export const SchoolsEdit: DynamicComponent = lazy(() => import("./Resources/Schools/SchoolsEdit"));
export const SchoolsList: DynamicComponent = lazy(() => import("./Resources/Schools/SchoolsList"));
export const SchoolsShow: DynamicComponent = lazy(() => import("./Resources/Schools/SchoolsShow"));
export const SponsorshipsCreate: DynamicComponent = lazy(() => import("./Resources/Sponsorships/SponsorshipsCreate"));
export const SponsorshipsEdit: DynamicComponent = lazy(() => import("./Resources/Sponsorships/SponsorshipsEdit"));
export const SponsorshipsList: DynamicComponent = lazy(() => import("./Resources/Sponsorships/SponsorshipsList"));
export const SponsorshipsShow: DynamicComponent = lazy(() => import("./Resources/Sponsorships/SponsorshipsShow"));
export const StripeBalanceTransactionsList: DynamicComponent = lazy(() => import("./Resources/StripeBalanceTransactions/StripeBalanceTransactionsList"));
export const StripeBalanceTransactionsShow: DynamicComponent = lazy(() => import("./Resources/StripeBalanceTransactions/StripeBalanceTransactionsShow"));
export const StripeChargesList: DynamicComponent = lazy(() => import("./Resources/StripeCharges/StripeChargesList"));
export const StripeChargesShow: DynamicComponent = lazy(() => import("./Resources/StripeCharges/StripeChargesShow"));
export const StripeInvoicesList: DynamicComponent = lazy(() => import("./Resources/StripeInvoices/StripeInvoicesList"));
export const StripeInvoicesShow: DynamicComponent = lazy(() => import("./Resources/StripeInvoices/StripeInvoicesShow"));
export const StripePaymentIntentsList: DynamicComponent = lazy(() => import("./Resources/StripePaymentIntents/StripePaymentIntentsList"));
export const StripePaymentIntentsShow: DynamicComponent = lazy(() => import("./Resources/StripePaymentIntents/StripePaymentIntentsShow"));
export const StripePayoutsList: DynamicComponent = lazy(() => import("./Resources/StripePayouts/StripePayoutsList"));
export const StripePayoutsShow: DynamicComponent = lazy(() => import("./Resources/StripePayouts/StripePayoutsShow"));
export const StripePricesList: DynamicComponent = lazy(() => import("./Resources/StripePrices/StripePricesList"));
export const StripePricesShow: DynamicComponent = lazy(() => import("./Resources/StripePrices/StripePricesShow"));
export const StripeProductsList: DynamicComponent = lazy(() => import("./Resources/StripeProducts/StripeProductsList"));
export const StripeProductsShow: DynamicComponent = lazy(() => import("./Resources/StripeProducts/StripeProductsShow"));
export const StripeSubscriptionsList: DynamicComponent = lazy(() => import("./Resources/StripeSubscriptions/StripeSubscriptionsList"));
export const StripeSubscriptionsShow: DynamicComponent = lazy(() => import("./Resources/StripeSubscriptions/StripeSubscriptionsShow"));
export const StripeWebhooksList: DynamicComponent = lazy(() => import("./Resources/StripeWebhooks/StripeWebhooksList"));
export const StripeWebhooksShow: DynamicComponent = lazy(() => import("./Resources/StripeWebhooks/StripeWebhooksShow"));
export const TagsCreate: DynamicComponent = lazy(() => import("./Resources/Tags/TagsCreate"));
export const TagsEdit: DynamicComponent = lazy(() => import("./Resources/Tags/TagsEdit"));
export const TagsList: DynamicComponent = lazy(() => import("./Resources/Tags/TagsList"));
export const TagsShow: DynamicComponent = lazy(() => import("./Resources/Tags/TagsShow"));
export const UsersCreate: DynamicComponent = lazy(() => import("./Resources/Users/UsersCreate"));
export const UsersEdit: DynamicComponent = lazy(() => import("./Resources/Users/UsersEdit"));
export const UsersList: DynamicComponent = lazy(() => import("./Resources/Users/UsersList"));
export const UsersShow: DynamicComponent = lazy(() => import("./Resources/Users/UsersShow"));
export const WiseWebhooksList: DynamicComponent = lazy(() => import("./Resources/WiseWebhooks/WiseWebhooksList"));
export const WiseWebhooksShow: DynamicComponent = lazy(() => import("./Resources/WiseWebhooks/WiseWebhooksShow"));
export const Coffee: DynamicComponent = lazy(() => import("./CustomPages/Static/Coffee"));
export const CreateInvoice: DynamicComponent = lazy(() => import("./CustomPages/Dynamic/CreateInvoice"));
export const FinanceSummary: DynamicComponent = lazy(() => import("./CustomPages/Dynamic/FinanceSummary"));
export const MyProfile: DynamicComponent = lazy(() => import("./CustomPages/Dynamic/MyProfile"));
