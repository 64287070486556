import { css } from '@emotion/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useSidebarState, useTranslate } from 'react-admin';

const classes = {
  righArrow: css`
    color: #e0e0e0;
  `,
};
export const SubMenu = ({
  isDropdownOpen = false,
  primaryText,
  leftIcon,
  children,
}: SubMenuProps) => {
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [isOpen, setIsOpen] = useState(isDropdownOpen);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <ListItemButton
        dense
        onClick={handleToggle}
        sx={{
          paddingLeft: '1rem',
          color: 'rgba(0, 0, 0, 0.54)',
        }}
      >
        {isOpen ? <ExpandMoreIcon /> : leftIcon}
        <ListItemText
          inset
          disableTypography
          primary={translate(primaryText || '')}
          sx={{
            paddingLeft: 2,
            fontSize: '1rem',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        />
        <ExpandMoreIcon css={classes.righArrow} />
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={
            open
              ? {
                  paddingLeft: '25px',
                  transition:
                    'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
              : {
                  paddingLeft: 0,
                  transition:
                    'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
                }
          }
        >
          {children}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export type SubMenuProps = {
  children?: React.ReactNode;
  isDropdownOpen?: boolean;
  leftIcon?: React.ReactElement | React.ReactNode;
  primaryText?: string;
};

export default SubMenu;
