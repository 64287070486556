import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title, useAuthenticated } from 'react-admin';

export const Dashboard = () => {
  useAuthenticated();

  return (
    <Card sx={{ marginTop: '20px', padding: '20px' }}>
      <Title title="Dashboard" />
      <CardContent>Dashboard</CardContent>
    </Card>
  );
};
