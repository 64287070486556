/* eslint-disable */
// @ts-ignore
// @ts-nocheck
import { Admin, CustomRoutes } from 'react-admin';

import { LoginPage } from './Auth/LoginPage';
import authProvider from './Auth/authProvider';
import { Dashboard } from './Dashboard';
import { dataProvider } from './DataProvider/dataProvider';
import Layout from './Layout/Layout';
import Theme from './Layout/Theme';
import { GetResourcesFromMenuList } from './utils/Resources/ResourceComponentList';
import { getRoutes } from './utils/Routes/getRoutes';

const routes = getRoutes();

export const App = () => {
  return (
    <Admin
      requireAuth
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      layout={Layout}
      theme={Theme}
      loginPage={LoginPage}
      disableTelemetry={true}
    >
      {GetResourcesFromMenuList}
      <CustomRoutes>{routes}</CustomRoutes>
    </Admin>
  );
};
