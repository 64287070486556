import { Route } from 'react-router-dom';

import { toPascalCase } from '../../Helpers/Helpers';
import * as Components from '../../generatedResourceImports';
import resourcesMenus, { MenuPropItem } from '../Menu/menuList';

// Define an interface for the Components object
interface ComponentsType {
  [key: string]: React.ComponentType<Record<string, unknown>> | undefined;
}

export function getRoutes() {
  const components: ComponentsType = Components; // Type assertion to ComponentsType

  const flattenedMenus = resourcesMenus
    .flatMap(
      (resource) =>
        resource.type === 'group'
          ? // Use optional chaining to handle potential undefined resources
            resource.resources?.filter(Boolean) // Recursively filter undefined in nested groups
          : resource.type === 'resource' ||
              resource.type === 'static' ||
              resource.type === 'dynamic'
            ? [resource] // Include matching top-level resources
            : [], // Exclude other types
    )
    .filter(Boolean) as MenuPropItem[];

  return flattenedMenus
    .filter((resource) =>
      ['resource', 'static', 'dynamic'].includes(resource.type),
    )
    .map((resource, index) => {
      if (['static', 'dynamic'].includes(resource.type)) {
        const Component = components[toPascalCase(resource.name)];

        if (Component) {
          return (
            <Route
              key={index}
              path={resource.to || `/${resource.name.toLowerCase()}`}
              element={<Component />}
            />
          );
        }
      }
      return null;
    })
    .filter(Boolean);
}
