import Divider from '@mui/material/Divider';
import { ReactElement } from 'react';
import { Menu as RAMenu } from 'react-admin';

import { useCan } from '../Auth/hasPermissionOrEnterpriseRole';
import { ucfirst } from '../Helpers/Helpers';
import resourcesMenus, { MenuProp } from '../utils/Menu/menuList';
import SubMenu from './SubMenu';

const getMenuItems = (item: MenuProp) => (
  <RAMenu.Item
    key={item.name}
    to={item.to || item.name.toLowerCase()}
    primaryText={item.label || ucfirst(item.name)}
    leftIcon={item.icon as ReactElement}
  />
);

const getSubMenu = (item: MenuProp) => (
  <SubMenu
    key={item.label}
    primaryText={item.label || ucfirst(item.name)}
    leftIcon={item.icon}
  >
    {item.resources?.map((subitem) => getMenuItems(subitem))}
  </SubMenu>
);

const getLineItem = () => {
  return (
    <Divider
      key="divider"
      sx={{ width: '90%', margin: '8px auto', borderColor: '#EEEEEE' }}
    />
  );
};

const typeMapping = {
  resource: getMenuItems,
  static: getMenuItems,
  dynamic: getMenuItems,
  group: getSubMenu,
  line: getLineItem,
};

const filterMenuByPermission = (
  menuList: MenuProp[],
  can: (permission: string) => boolean,
): MenuProp[] => {
  const filteredMenu: MenuProp[] = [];

  menuList.forEach((item) => {
    if (
      item.permission === false ||
      (item.permission === 'default' &&
        can(item.name.toLowerCase() + '_list')) ||
      (typeof item.permission === 'string' && can(item.permission)) ||
      item.type === 'group' ||
      item.type === 'line'
    ) {
      if (item.resources) {
        // Recursively filter sub-resources
        const filteredResources = filterMenuByPermission(item.resources, can);
        if (filteredResources.length > 0) {
          // Only include the group if it has at least one accessible resource
          const filteredGroup = { ...item, resources: filteredResources };
          filteredMenu.push(filteredGroup);
        }
      } else {
        // Include individual resource/static/dynamic items
        filteredMenu.push(item);
      }
    }
  });

  return filteredMenu;
};

const Menu = () => {
  const can = useCan();

  const menuR = filterMenuByPermission(resourcesMenus, can);

  const menu = menuR.flatMap((item) =>
    typeMapping[item.type] && !item.hide ? typeMapping[item.type](item) : [],
  );

  return (
    <RAMenu>
      <RAMenu.DashboardItem />
      {menu}
    </RAMenu>
  );
};

export default Menu;
