
// export const getDeep = (path: string[], object: [] | object | string[]): [] | object | string | number =>
//   path.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), object);


export type ObjectType = {
  [key: string]: unknown;
};

export type ArrayType = unknown[];

export const isObjectType = (arg: unknown): arg is ObjectType => {
  return typeof arg === 'object' && arg !== null && !Array.isArray(arg);
};

export const getDeepFromObjectOrArray = (
  path: string[],
  objectOrArray: unknown
): unknown => {
  let result: unknown = objectOrArray;

  for (const key of path) {
    if (typeof result === 'object' && result !== null) {
      result = (result as { [key: string]: unknown })[key];
    } else {
      return null;
    }
  }

  return result;
};

export const getDeep = getDeepFromObjectOrArray





export const sleep = (milliseconds: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const CroDate = (date: string): string =>
  new Date(date).toLocaleDateString('hr-HR');

export const ucfirst = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const lcfirst = (string: string): string =>
  string.charAt(0).toLowerCase() + string.slice(1);

export const isEmpty = (string: string): boolean =>
  !string || string.length === 0;

export const isBlank = (string: string): boolean =>
  !string || /^\s*$/.test(string);

export const showUrl = (resource: string, id: string): string =>
  `/${resource}/${id}/show`;

export const editUrl = (resource: string, id: string): string =>
  `/${resource}/${id}/edit`;

export const listUrl = (resource: string): string => `/${resource}`;

function getTag(value: unknown): string {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]';
  }
  return Object.prototype.toString.call(value);
}

function isObjectLike(value: unknown): boolean {
  return typeof value === 'object' && value !== null;
}

export const isPlainObject = (value: unknown): boolean => {
  if (!isObjectLike(value) || getTag(value) !== '[object Object]') {
    return false;
  }
  if (Object.getPrototypeOf(value) === null) {
    return true;
  }
  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(value) === proto;
};

export const flattenObject = (
  obj: Record<string, unknown>,
): Record<string, unknown> => {
  const flattened: Record<string, unknown> = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key] as Record<string, unknown>));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
};


export const getLinkToResource = (
  resource: string,
  filter: Record<string, unknown>,
  order: string = 'ASC',
  page: string = '1',
  perPage: string = '25',
  sort: string = 'id',
): string => {
  const displayedFilters: Record<string, boolean> = {};

  Object.keys(filter).forEach((key) => {
    displayedFilters[key] = true;
  });

  return (
    resource +
    '?' +
    new URLSearchParams({
      displayedFilters: JSON.stringify(displayedFilters),
      filter: JSON.stringify(filter),
      order: order,
      page: page,
      perPage: perPage,
      sort: sort,
    }).toString()
  );
};

export const getNameInitials = (name: string): string => {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase();
};

export const isSixDigits = (str: string): boolean => /^\d{6}$/.test(str);

export const toCamelCase = (str: string): string => {
  return str.replace(/[-_](.)/g, (_, char) => char.toUpperCase());
};

export const toPascalCase = (str: string): string => {
  return str
    .replace(/[-_](.)/g, (_, char) => char.toUpperCase())
    .replace(/^\w/, (char) => char.toUpperCase());
};


export const getTextColor = (hexColor: string | null): 'black' | 'white' => {

  if (!hexColor) {
    return 'black'
  }
  // Remove the hash if present
  hexColor = hexColor.replace(/^#/, '');

  // Convert hex to RGB
  const bigint = parseInt(hexColor, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Calculate relative luminance (brightness) using the formula for sRGB
  const luminance = 0.2126 * r / 255 + 0.7152 * g / 255 + 0.0722 * b / 255;

  // Choose white or black based on luminance
  return luminance > 0.5 ? 'black' : 'white';
};


export const filterObjectByKeys = <T extends object>(obj: T, keys: string[]): Partial<T> => {
  // Use object destructuring to create a new object with only the specified keys
  return {
    ...Object.fromEntries(
      // Filter the object entries based on the provided keys
      Object.entries(obj).filter(([key]) => keys.includes(key))
    ),
  } as Partial<T>; // Cast the result to Partial<T>
};



export const formatDbStringToUpperCase = (str: string) => {
  // Split the string on underscores and capitalize the first letter of each word
  return str.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};



export const cutUrl = (text: string) => {
  // Combine regular expressions for "api/" and "artisan"
  const pattern = /\/api\/|\bartisan\b/;

  // Find the first occurrence using a combined regular expression
  const match = pattern.exec(text);

  // If no match is found, return the original text
  if (!match) return text;

  // Extract the part after the match (excluding the matched word)
  const startIndex = match.index + match[0].length; // Adjust for match length
  return text.slice(startIndex);
};