// in src/MyLayout.js
// import { useEffect, useState } from "react";
import { LayoutProps, Layout as RALayout } from 'react-admin';

// import MyMenu from "./Menu";
import { AppBar } from './AppBar';
import Menu from './Menu';

// import Error from "../Basic/DefaultError";

const Layout = (props: LayoutProps) => {
  //   const [twofa, setTwofa] = useState(false);
  //   const google2fa = localStorage.getItem("google2fa");

  //   useEffect(() => {
  //     if (google2fa === "true") {
  //       setTwofa(true);
  //     }
  //   }, [google2fa]);

  return (
    <RALayout
      {...props}
      appBar={AppBar}
      //   error={Error}
      style={{ maxWidth: '100vw' }}
      //   {...(twofa ? { menu: MyMenu } : {})}
      menu={Menu}
    />
  );
};

export default Layout;
