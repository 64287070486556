interface Props {
  name: string;
  outlined?: string | null;
  style?: object;
}

export const IconFont = ({ name, outlined = null, style = {} }: Props) => {
  return (
    <span
      className={outlined ? 'material-icons-outlined' : 'material-icons'}
      style={style}
    >
      {name}
    </span>
  );
};
