import TextField from '@mui/material/TextField';
import { ReactNode } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

interface FormInputProps {
  name: string;
  control: Control<FieldValues> | undefined;
  label: string;
  setValue?: unknown;
  required: boolean;
  rules?: object;
  defaultValue: string | number;
  type?: string;
  autocomplete?: string;
  autoFocus?: boolean;
}

export const FormInputText = ({
  name,
  control,
  label,
  rules = {},
  defaultValue = '',
  type = '',
  autocomplete = 'on',
  autoFocus,
}: FormInputProps) => {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const error = formState['errors'][name] ?? null;

        return (
          <TextField
            {...field}
            helperText={error ? (error.message as ReactNode) : undefined}
            size="small"
            error={!!error}
            fullWidth
            label={label}
            variant="outlined"
            type={type}
            autoComplete={autocomplete}
            autoFocus={autoFocus}
          />
        );
      }}
    />
  );
};
