import { stringify } from 'query-string';
import { DataProvider, fetchUtils } from 'ra-core';
import { CreateParams, RaRecord } from 'react-admin';

const httpClient = (url: string, options: fetchUtils.Options = {}) => {

  const customHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers;


  const token = localStorage.getItem('token');

  if (token) {
    customHeaders.set('Authorization', `Bearer ${token}`);
  }

  options.headers = customHeaders;

  return fetchUtils.fetchJson(url, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface GetResult<RecordType extends RaRecord = any> {
  data: RecordType;
}


// export interface Data extends DataProvider<ResourceType extends string = string> {

export interface MyDataProvider extends DataProvider {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: <RecordType extends RaRecord = any>(
    resource: string,
  ) => Promise<GetResult<RecordType>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadImageFile: <RecordType extends RaRecord = any>(
    resource: string, params: CreateParams
  ) => Promise<GetResult<RecordType>>;
}

const myDataProvider = (apiUrl: string, httpClient = fetchUtils.fetchJson): MyDataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
      _filter: JSON.stringify(params.filter)
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }

      const totalCountHeader = headers.get('x-total-count');
      const totalCount = totalCountHeader ? totalCountHeader.split('/').pop() : undefined;

      if (totalCount !== undefined) {
        return {
          data: json,
          total: parseInt(totalCount, 10),
        };
      } else {
        throw new Error('Unexpected format of X-Total-Count header.');
      }

      // return {
      //   data: json,
      //   total: parseInt(
      //     headers.get('x-total-count').split('/').pop(),
      //     10
      //   ),
      // };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  get: (resource: string) =>
    httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    // const query = {
    //   id: params.ids,
    // };
    const query = {
      [`id_like`]: params.ids.join('|')
    }

    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...fetchUtils.flattenObject(params.filter),
      [params.target]: params.id,
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
      _filter: JSON.stringify({
        ...{ [params.target]: params.id },
        ...params.filter
      })

    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      if (!headers.has('x-total-count')) {
        throw new Error(
          'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
        );
      }

      const totalCountHeader = headers.get('x-total-count');
      const totalCount = totalCountHeader ? totalCountHeader.split('/').pop() : undefined;

      if (totalCount !== undefined) {
        return {
          data: json,
          total: parseInt(totalCount, 10),
        };
      } else {
        throw new Error('Unexpected format of X-Total-Count header.');
      }

      // return {
      //   data: json,
      //   total: parseInt(
      //     headers.get('x-total-count').split('/').pop(),
      //     10
      //   ),
      // };
    });
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },


  //TODO: delete this option, not used, transfered to create
  uploadImageFile: (resource, params) => {

    const formData = new FormData();
    params.data.imageFile?.rawFile && formData.append("file", params.data.imageFile.rawFile);
    params.data.title && formData.append("title", params.data.title);
    params.data.content && formData.append("content", params.data.content);
    formData.append("data", JSON.stringify(params.data));

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,

    })
      .then(({ json }) => ({       // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: { ...params.data, id: json.id } as any,
      }));
  },

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params),
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) => {

    if (resource === 'erstereports/upload') {

      const formData = new FormData();
      params.data.file && formData.append("file", params.data.file);
      params.data.file?.name && formData.append("name", params.data.file?.name);
      formData.append("data", JSON.stringify(params.data));

      return httpClient(`${apiUrl}/${resource}`, {
        method: "POST",
        body: formData,

      })
        .then(({ json }) => ({       // eslint-disable-next-line @typescript-eslint/no-explicit-any
          data: { ...params.data, id: json.id } as any,
        }));
    }


    if (!params.data.imageFile) {
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: { ...params.data, id: json.id } as any,
      }))

    }

    const formData = new FormData();
    params.data.imageFile?.rawFile && formData.append("file", params.data.imageFile.rawFile);
    params.data.title && formData.append("title", params.data.title);
    params.data.content && formData.append("content", params.data.content);
    formData.append("data", JSON.stringify(params.data));

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: formData,

    })
      .then(({ json }) => ({       // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: { ...params.data, id: json.id } as any,
      }));

  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map(id =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: 'DELETE',
        })
      )
    ).then(responses => ({ data: responses.map(({ json }) => json.id) })),

});









//////////////////









// export const imageDataProvider = {
//   ...myDataProvider,
//   create: (resource, params) => {
//     if (resource === "children") {
//       const formData = createPostFormData(params);
//       return fetchUtils
//         .fetchJson(`${import.meta.env.VITE_API_URL}/${resource}`, {
//           method: "POST",
//           body: formData,
//         })
//         .then(({ json }) => ({ data: json }));
//     }

//     return myDataProvider.create(resource, params);
//   },
//   update: (resource, params) => {
//     if (resource === "children") {
//       const formData = createPostFormData(params);
//       formData.append("id", params.id);
//       return fetchUtils
//         .fetchJson(`${import.meta.env.VITE_API_URL}/${resource}`, {
//           method: "PUT",
//           body: formData,
//         })
//         .then(({ json }) => ({ data: json }));
//     }

//     return myDataProvider.update(resource, params);
//   },
// };

// export const dataProvider = imageDataProvider

export const dataProvider = myDataProvider(
  import.meta.env.VITE_API_URL,
  httpClient,
);