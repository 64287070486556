import { css } from '@emotion/react';
import { Button, CircularProgress } from '@mui/material';

const classes = {
  button: css`
    width: 100%;
  `,
  icon: () => css`
    margin-right: 8px;
  `,
};

interface Props {
  loading: boolean;
  disabled: boolean;
  onClick: () => void;
  label?: string;
}

export const AuthSaveButton = ({
  loading,
  disabled,
  onClick,
  label = 'Sign In',
}: Props) => {
  return (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      disabled={loading || disabled}
      css={classes.button}
      onClick={onClick}
    >
      {loading && (
        <CircularProgress css={classes.icon} size={18} thickness={2} />
      )}
      {loading ? 'Please wait...' : label}
    </Button>
  );
};
