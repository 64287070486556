import { SerializedStyles, css } from '@emotion/react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Favorite from '@mui/icons-material/Favorite';
import ListIcon from '@mui/icons-material/List';
import People from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { ReactNode } from 'react';
import { CSSObjectWithLabel, InputActionMeta } from 'react-select';
import AsyncSelect from 'react-select/async';

import { dataProvider } from '../../DataProvider/dataProvider';
import {
  GlobalSearchResult,
  GlobalSearchResultDropdown,
} from '../../Types/types';
import { IconFont } from '../IconFont';

const classes = {
  root: css`
    color: black;
    flex-grow: 1;
    margin-right: 20px;
    max-width: 350px;
    bordre-box: 4;
  `,
  avatar: css`
    background: '#bdbdbd';
  `,
  iconAvatar: css`
    background: '#f5f5f5';
  `,
  iconFontErste: css`
    color: '#00497b';
  `,
};

const selectStyles = {
  menu: (base: CSSObjectWithLabel) => ({
    ...base,
    zIndex: 100,
    height: 'auto',
    boxShadow:
      '0px 0px 4px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px 1px rgba(0,0,0,0.14), 1px -1px 14px 2px rgba(0,0,0,0.12)',
  }),
  menuList: (base: CSSObjectWithLabel) => ({
    ...base,
    maxHeight: '90vh',
  }),
  valueContainer: (base: CSSObjectWithLabel) => ({
    ...base,
    cursor: 'text',
  }),
  control: (base: CSSObjectWithLabel) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.35)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.45)',
      borderColor: 'none !important',
      border: 'none !important',
    },
  }),
  input: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'white',
    '& input': {
      fontWeight: 'bold',
    },
  }),
  placeholder: (base: CSSObjectWithLabel) => ({
    ...base,
    color: 'rgba(255, 255, 255, 0.6)',
  }),
};

export const GlobalSearch = React.memo(() => {
  const [options, setOptions] = React.useState<
    GlobalSearchResultDropdown[] | undefined
  >([]);
  const [stateInputValue, setInputValue] = React.useState('');

  const onInputChange = (inputValue: string, action: InputActionMeta) => {
    if (action.action === 'input-blur' || action.action === 'menu-close') {
      return;
    }

    setInputValue(inputValue);

    if (!inputValue) {
      setOptions([]);
    }
  };

  const debouncing = async (inputValue: string) => {
    const ops = await debouncedLoadOptions2(inputValue);
    setOptions(ops);
    return ops;
  };

  const loadOptions = async (inputValue: string) => {
    return await debouncing(inputValue);
  };

  return (
    <div css={classes.root}>
      <AsyncSelect
        onInputChange={onInputChange}
        inputValue={stateInputValue}
        loadOptions={loadOptions}
        defaultOptions={options}
        isClearable={true}
        placeholder="Search..."
        noOptionsMessage={() => (
          <Typography color="textSecondary">No results</Typography>
        )}
        {...{
          components: {
            Option,
            SingleValue,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          },
          classes,
        }}
        styles={selectStyles}
      />
    </div>
  );
});

GlobalSearch.displayName = 'GlobalSearch';

const wait = 350;

const loadiOptions = (inputValue: string) => getSearchResults(inputValue);

const debouncedLoadOptions2 = AwesomeDebouncePromise(loadiOptions, wait);

const getSearchResults = (inputValue: string) => {
  if (!inputValue) {
    return [];
  }

  if (inputValue.length < 3) {
    return [];
  }

  return dataProvider
    .get('search/' + inputValue)
    .then(({ data }: { data: GlobalSearchResult[] }) => {
      if (data.length) {
        const ops: GlobalSearchResultDropdown[] = [];

        data.map((resultItem) => {
          ops.push({
            value: resultItem.subjectTitle,
            label: resultItem.subjectTitle,
            ...resultItem,
          });

          return null;
        });

        return ops;
      }

      return [];
    });
};

const GetAvatar = React.memo(
  ({ data }: { data: GlobalSearchResultDropdown }) => {
    if (data.type === 'child') {
      if (data.image) {
        return (
          <Avatar
            style={{ background: '#f50057' }}
            alt={data.subjectTitle}
            src={
              import.meta.env.VITE_IMG_URL + '/childrenimg/thumb/' + data.image
            }
          />
        );
      } else {
        return (
          <IconAvatar>
            <Favorite />
          </IconAvatar>
        );
      }
    } else if (data.type === 'human') {
      return (
        <IconAvatar>
          <People />
        </IconAvatar>
      );
    } else if (data.type === 'school') {
      return (
        <IconAvatar>
          <SchoolIcon />
        </IconAvatar>
      );
    } else if (data.type === 'schoolprogramme') {
      return (
        <IconAvatar>
          <SchoolOutlinedIcon />
        </IconAvatar>
      );
    } else if (data.type === 'link') {
      return (
        <IconAvatar>
          <ListIcon />
        </IconAvatar>
      );
    } else if (data.type === 'invoice') {
      return (
        <IconAvatar>
          <AttachMoney />
        </IconAvatar>
      );
    } else if (data.type === 'erstereport') {
      return (
        <IconAvatar styles={classes.iconAvatar}>
          <IconFont css={classes.iconFontErste} name="request_quote" />
        </IconAvatar>
      );
    }

    return (
      <IconAvatar>
        <ArrowRightIcon />
      </IconAvatar>
    );
  },
);

GetAvatar.displayName = 'GetAvatar';

const IconAvatar = React.memo(
  ({
    children,
    styles,
  }: {
    children: ReactNode;
    styles?: SerializedStyles;
  }) => <Avatar css={[classes.avatar, styles]}>{children}</Avatar>,
);
IconAvatar.displayName = 'IconAvatar';

const Option = React.memo(
  ({
    data,
    isFocused,
    isSelected,
  }: {
    data: GlobalSearchResultDropdown;
    isFocused: boolean;
    isSelected: boolean;
  }) => {
    let subText = null;

    let to = '#';

    if (data.type === 'child') {
      subText = data.school;
      to += '/children/' + data.subjectId + '/show';
    } else if (data.type === 'human') {
      subText = data.email;
      to += '/humans/' + data.subjectId + '/show';
    } else if (data.type === 'school') {
      subText = data.email;
      to += '/schools/' + data.subjectId + '/show';
    } else if (data.type === 'schoolprogramme') {
      subText = data.extraInfo;
      to += '/schoolprogrammes/' + data.subjectId + '/show';
    } else if (data.type === 'link') {
      subText = data.email;
      to += '/' + data.subjectId;
    } else if (data.type === 'invoice') {
      subText = data.subjectId + ' - ' + data.extraInfo;
      to += '/invoices/' + data.subjectId + '/show';
    } else if (data.type === 'erstereport') {
      subText = data.extraInfo + ' HRK';
      to += '/erstereports/' + data.subjectId + '/show';
    }

    return (
      <ListItemButton
        component="a"
        href={to}
        selected={isFocused}
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        <ListItemAvatar>
          <GetAvatar data={data} />
        </ListItemAvatar>
        <ListItemText primary={data.label} secondary={subText} />
      </ListItemButton>
    );
  },
);

Option.displayName = 'Option';

const SingleValue = React.memo(({ children }: { children: ReactNode }) => (
  <Typography>{children}</Typography>
));
SingleValue.displayName = 'SingleValue';
