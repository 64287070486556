import { css } from '@emotion/react';
import LockIcon from '@mui/icons-material/Lock';
import { Avatar, Card } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import { useCheckAuth } from 'react-admin';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoginForm } from './LoginForm';
import { SignupForm } from './SignupForm';

// import { ThemeOptions } from '@mui/material/styles';

const classes = {
  main: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 1px;
    align-items: center;
    justify-content: flex-start;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: radial-gradient(
      circle at 50% 14em,
      #313264 0%,
      #00023b 60%,
      #00023b 100%
    );
    background-position: center;
  `,
  card: css`
    min-width: 300px;
    margin-top: 6em;
  `,

  avatar: css`
    margin: 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `,
  icon: (theme: Theme) => css`
    background-color: ${theme.palette.grey[500]};
  `,
  adminTitle: css`
    display: block;
  `,
};

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
    captchaOnLoad: () => void;
  }
}

interface ReCaptchaInstance {
  ready<U>(arg: U): void;
  execute: (key: string, options: ReCaptchaExecuteOptions) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => void;
}

interface ReCaptchaExecuteOptions {
  action: string;
}

interface ReCaptchaRenderOptions {
  sitekey: string;
  size: 'invisible';
}

export const LoginPage = () => {
  const backgroundImage = '2.jpg';
  const containerRef = useRef<HTMLDivElement>(null);
  let backgroundImageLoaded = false;

  const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(
    undefined,
  );
  const checkAuth = useCheckAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const signup = searchParams.get('signup');
  const ttk = searchParams.get('ttk');

  /** Check if the user is already logged in */
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate('/');
      })
      .catch(() => {
        // not authenticated, stay on the login page
      });
  }, [checkAuth, navigate]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
  });

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(import.meta.env.VITE_RECAPTCHA_KEY, {
          action: 'login',
        })
        .then((token) => {
          setRecaptchaToken(token);
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement('script');
    script.src =
      'https://www.google.com/recaptcha/api.js?render=' +
      import.meta.env.VITE_RECAPTCHA_KEY;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);
  }, []);

  if (signup) {
    return (
      <div css={classes.main}>
        <Card css={classes.card}>
          <div css={classes.avatar}>
            <div css={classes.adminTitle}>Signup</div>
            <Avatar css={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <SignupForm recaptchaToken={recaptchaToken} token={ttk} />
          <div
            className="g-recaptcha"
            data-sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
            data-size="invisible"
          ></div>
        </Card>
      </div>
    );
  }

  return (
    // <ThemeProvider theme={muiTheme}>
    <div css={classes.main} ref={containerRef}>
      <Card css={classes.card}>
        <div css={classes.avatar}>
          <div css={classes.adminTitle}>NextAdmin</div>
          <Avatar css={classes.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <LoginForm recaptchaToken={recaptchaToken} />
        <div
          className="g-recaptcha"
          data-sitekey={import.meta.env.VITE_RECAPTCHA_KEY}
          data-size="invisible"
        ></div>
      </Card>
    </div>
    // </ThemeProvider>
  );
};
