import { usePermissions } from 'react-admin';

import { Permissions } from '../Types/types';

export const hasPermissionOrEnterpriseRole = (
  permissions: Permissions,
  resource: string | undefined,
  type: '_create' | '_export' | '_delete',
): boolean => {
  if (!resource) {
    return false;
  }
  const permissionToCheck = resource + type; // Concatenating resource and type

  // Check if the user has the specific permission or role
  const hasPermission =
    permissions?.permissions?.includes(permissionToCheck) || false;
  const hasEnterpriseRole = permissions?.roles?.includes('enterprise') || false;

  return hasPermission || hasEnterpriseRole;
};

export const useCan = () => {
  const permissions = usePermissions();

  const can = (permission: string): boolean => {
    return (
      permissions.permissions.hasRole('administrator') ||
      permissions.permissions.hasPermission(permission)
    );
  };

  return can;
};

export const useHasRole = () => {
  const permissions = usePermissions();

  const hasRole = (role: string): boolean => {
    return permissions.permissions.hasRole(role);
  };

  return hasRole;
};
