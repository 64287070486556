import { css } from '@emotion/react';
import { CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

import { IApiError } from '../DataProvider/fetchResource';
import { getDeep } from '../Helpers/Helpers';
import { useFetchPost } from '../Hooks/useFetchPost';
import { AuthSaveButton } from './AuthSaveButton';
import { FormInputText } from './FormInputText';

const classes = {
  form: css`
    padding: 0 1em 1em 1em;
    display: grid;
    grid-row-gap: 20px;
    padding: 20px;
    margin: 10px 40px;
  `,
  input: css`
    margin-top: 1em;
  `,
  button: css`
    width: 100%;
  `,
  icon: () => css`
    margin-right: 8px;
  `,
  errorMessage: css`
    text-align: center;
    color: red;
    padding: 5px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  `,
};

interface SignupFormProps {
  recaptchaToken?: string;
  token: string | null;
}

type SignupRequestBody = {
  email: string;
  password: string;
  grecaptcha?: string;
  firstName: string;
  token: string;
};

type SignupRequestResponse = {
  status: 'success' | 'error';
};

export const SignupForm = ({ recaptchaToken, token }: SignupFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [shouldLogin, setShouldLogin] = useState(false);

  const notify = useNotify();

  const { control, handleSubmit, formState, watch, ...methods } = useForm({
    mode: 'onChange',
  });

  const { isValid } = formState;

  const email = watch('email');
  const password = watch('password');
  const firstName = watch('firstName');

  const signupData: SignupRequestBody = {
    email: email,
    password: password,
    grecaptcha: recaptchaToken,
    firstName: firstName,
    token: token ?? '',
  };

  const onClick = () => {
    if (!token) {
      setError('Error TM');
      return;
    }
    setError('');
    setLoading(true);
    doSignup();
  };

  const [doSignup, { data: signupDataResponse, error: signupError }] =
    useFetchPost<SignupRequestResponse>('/auth/signup', signupData);

  useEffect(() => {
    if (signupDataResponse && !signupError) {
      if (signupDataResponse.status === 'success') {
        setLoading(false);
        setShouldLogin(true);
      }
    }

    if (signupError) {
      const typedFirstSignupError = signupError as IApiError;

      setLoading(false);

      if (
        getDeep(['response', 'errors', 'grecaptcha'], typedFirstSignupError) !==
        null
      ) {
        notify(
          'CAPTCHA error detected. Please reload the page and try again.',
          { type: 'warning' },
        );
        setError(
          'CAPTCHA error detected. Please reload the page and try again. (Ctrl+R)',
        );
      }

      if (typedFirstSignupError.status === 500) {
        notify(
          'Server Error encountered. Please retry or report the issue in the Slack channel.',
        );
        setError(
          'Server Error encountered. Please retry or report the issue in the Slack channel.',
        );
      }

      notify('Error', { type: 'warning' });
    }
  }, [notify, signupDataResponse, signupError]);

  if (shouldLogin) {
    return (
      <Box sx={{ margin: '20px' }}>
        <p>
          🌟 Great job! {`You've`} successfully signed up, and {`we're`}{' '}
          thrilled to have you on board. Now, {`let's`} take the next step
          together!
        </p>
        <p>
          Click the link below to log in and proceed to the next stage of your
          exciting journey.
        </p>
        <p>
          {`You're`} almost there – just a few clicks away from unlocking the
          magic! 🚀✨
        </p>
        <Box sx={{ textAlign: 'center' }}>
          <a href="/#/login">Login</a>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {error && <p css={classes.errorMessage}>{error}</p>}

      <FormProvider {...(methods as UseFormReturn<FieldValues>)}>
        <form noValidate>
          <div css={classes.form}>
            <FormInputText
              defaultValue={''}
              name="firstName"
              label="First name as it appears on your ID"
              control={control}
              required
              rules={{
                required: true,
              }}
              autocomplete="off"
            />
            <FormInputText
              defaultValue={''}
              name="email"
              label="Your Circle of Love Email"
              control={control}
              required
              rules={{
                required: {
                  value: true,
                },
              }}
              type="email"
              autocomplete="off"
            />
            <FormInputText
              defaultValue={''}
              name="password"
              label="Password"
              control={control}
              required
              rules={{
                required: {
                  value: true,
                },
                minLength: {
                  value: 16,
                  message: 'Password must be at least 16 characters long',
                },
              }}
              type="password"
              autocomplete="off"
            />
          </div>

          <CardActions>
            <AuthSaveButton
              label="Signup"
              loading={loading || !recaptchaToken}
              disabled={!isValid}
              onClick={handleSubmit(onClick)}
            />
          </CardActions>
        </form>
      </FormProvider>
    </>
  );
};
