import { useState } from 'react';

import { ApiPostBody, IApiError, apiPost } from '../DataProvider/fetchResource';

export const useFetchPost = <T>(path: string, body: ApiPostBody, userOptions = {}): [
  () => Promise<void>,
  {
    data: T | Response | undefined,
    loading: boolean,
    error: IApiError | unknown | null
  }
] => {
  const [data, setData] = useState<Response | undefined>(undefined);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<IApiError | unknown | null>(null);

  async function fetchUrl() {
    setError(null);
    try {
      setLoading(true);
      const response = await apiPost(path, body, userOptions);
      setData(response || 'Data error');
      setError(null);
      setLoading(false);
    } catch (error: unknown) {
      setError(error as IApiError);
    }
  }

  return [fetchUrl, { data, loading, error }];
};
