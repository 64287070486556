import PersonIcon from '@mui/icons-material/Person';
import { memo } from 'react';
import {
  Logout,
  MenuItemLink,
  AppBar as RAAppBar,
  TitlePortal,
  UserMenu,
} from 'react-admin';

import { useCan } from '../Auth/hasPermissionOrEnterpriseRole';
import { GlobalSearch } from '../Components/GlobalSearch/GlobalSearch';

export const AppBar = memo(() => {
  const can = useCan();

  return (
    <RAAppBar
      userMenu={
        <UserMenu>
          <MenuItemLink
            to="me"
            leftIcon={<PersonIcon />}
            primaryText="My profile"
          >
            My profile
          </MenuItemLink>
          <Logout />
        </UserMenu>
      }
    >
      <TitlePortal />
      {can('x_globalsearch') && <GlobalSearch />}
    </RAAppBar>
  );
});

AppBar.displayName = 'AppBar';

// import React, { cloneElement } from "react";
// import PropTypes from "prop-types";

// import { AppBar as MuiAppBar } from "@mui/material";
// import { Toolbar } from "@mui/material";
// import { IconButton } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Typography } from "@mui/material";

// import { LoadingIndicator } from "react-admin";
// import { css } from "@emotion/react";

// const classes = {
//   appBar: css`
//     /* transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }), */
//     z-index: 1300;
//   `,
//   toolbar: css`
//     padding-right: 24;
//   `,
//   menuButton: css`
//     margin-left: 0.5em;
//     margin-right: 0.5em;
//   `,
//   menuButtonIconClosed: css`
//     /* transition: theme.transitions.create(["transform"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     transform: "rotate(0deg)", */
//   `,
//   menuButtonIconOpen: css`
//     /* transition: theme.transitions.create(["transform"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     transform: "rotate(180deg)", */
//   `,
//   title: css`
//     flex: 1;
//   `,
//   loadingIndicator: css`
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     z-index: 1200;
//     margin-bottom: 16;
//     margin-top: 16;
//     margin-left: auto;
//     margin-right: auto;
//   `,
//   logout: (theme) => css`
//     color: ${theme.palette.secondary.contrastText};
//   `,
//   username: css`
//     margin-right: 15px;
//   `,
// };

// export const AppBar = ({ logout, open, title, toggleSidebar }) => (
//   <MuiAppBar css={classes.appBar} color="secondary" position="absolute">
//     <Toolbar disableGutters css={classes.toolbar}>
//       <IconButton
//         color="inherit"
//         aria-label="open drawer"
//         onClick={toggleSidebar}
//         css={classes.menuButton}
//         size="large"
//       >
//         <MenuIcon
//           css={{
//             root: open
//               ? classes.menuButtonIconOpen
//               : classes.menuButtonIconClosed,
//           }}
//         />
//       </IconButton>
//       <Typography variant="title" color="inherit" css={classes.title}>
//         {typeof title === "string" ? title : React.cloneElement(title)}
//       </Typography>
//       <Typography color="inherit" css={classes.username}>
//         {localStorage.getItem("name")}
//       </Typography>

//       {logout &&
//         cloneElement(logout, {
//           className: classes.logout,
//         })}
//     </Toolbar>
//     <LoadingIndicator css={classes.loadingIndicator} />
//   </MuiAppBar>
// );

// AppBar.propTypes = {
//   classes: PropTypes.object,
//   className: PropTypes.string,
//   logout: PropTypes.element,
//   open: PropTypes.bool,
//   title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
//   toggleSidebar: PropTypes.bool,
// };
