import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useLogin, useLogout, useNotify } from 'react-admin';
import { useForm } from 'react-hook-form';

import { IApiError } from '../DataProvider/fetchResource';
import { getDeep } from '../Helpers/Helpers';
import { useFetchPost } from '../Hooks/useFetchPost';
import { AuthSaveButton } from './AuthSaveButton';
import { Create2FaForm } from './Create2FaForm';
import { LoginForm2Fa } from './LoginForm2Fa';
import { LoginFormUsername } from './LoginFormUsername';

const classes = {
  errorMessage: css`
    text-align: center;
    color: red;
    padding: 5px;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  `,
};

// interface IFormInputs {
//   email: string;
//   password: string;
//   code: string;
// }

interface LoginFormProps {
  recaptchaToken?: string;
}

type LoginRequestBody = {
  email: string;
  password: string;
  grecaptcha?: string;
};

type Create2FaRequestBody = {
  token: string;
  code: string;
};

type LoginRequestResponse = {
  twofa: boolean;
  token: string;
  create2fa?: boolean;
  access_token?: string;
};

type Create2FaRequestResponse = {
  status: string;
};

export const LoginForm = ({ recaptchaToken }: LoginFormProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [twoFa, setTwoFa] = useState<boolean>(false);
  const [create2Fa, setCreate2Fa] = useState<boolean>(false);
  const login = useLogin();
  const notify = useNotify();
  const logout = useLogout();

  const { control, handleSubmit, formState, watch, ...methods } = useForm({
    mode: 'onChange',
  });

  const { isValid } = formState;

  const email = watch('email');
  const password = watch('password');
  const code = watch('code');

  const [twoFaToken, setTwoFaToken] = useState<string | null>(null);

  const loginData: LoginRequestBody = {
    email: email,
    password: password,
    grecaptcha: recaptchaToken,
  };

  const create2FaRequestData: Create2FaRequestBody = {
    code: code,
    token: localStorage.getItem('twoFaQrCode') ?? '',
  };

  const [doLogin, { data: authData, error: firstLoginError }] =
    useFetchPost<LoginRequestResponse>('/auth/login', loginData);

  const [
    sendCreate2Fa,
    { data: create2FaData, error: create2FaError, loading: create2FaLoading },
  ] = useFetchPost<Create2FaRequestResponse>(
    '/2fa/enable',
    create2FaRequestData,
  );

  useEffect(() => {
    if (create2FaData?.status === 'success') {
      notify('Success.');
      logout();
      window.location.reload();
    } else if (create2FaError) {
      notify('Error');
    }
  }, [create2FaData?.status, create2FaError, logout, notify]);

  /** Normal Login */
  useEffect(() => {
    const loginResponseData = authData as LoginRequestResponse;

    if (loginResponseData && !firstLoginError) {
      if (loginResponseData.twofa === true) {
        setTwoFaToken(loginResponseData.token);
        setTwoFa(true);
        setLoading(false);
      } else if (loginResponseData.create2fa === true) {
        setTwoFaToken(loginResponseData.token);
        localStorage.setItem('token', loginResponseData.access_token ?? '');
        setCreate2Fa(true);
        setLoading(false);
      }
    }

    if (firstLoginError) {
      const typedFirstLoginError = firstLoginError as IApiError;

      setLoading(false);

      if (
        getDeep(['response', 'errors', 'grecaptcha'], typedFirstLoginError) !==
        null
      ) {
        notify(
          'CAPTCHA error detected. Please reload the page and try again.',
          { type: 'warning' },
        );
        setError(
          'CAPTCHA error detected. Please reload the page and try again. (Ctrl+R)',
        );
      }

      if (typedFirstLoginError.status === 500) {
        notify(
          'Server Error encountered. Please retry or report the issue in the Slack channel.',
        );
        setError(
          'Server Error encountered. Please retry or report the issue in the Slack channel.',
        );
      }

      notify(
        typeof typedFirstLoginError === 'string'
          ? typedFirstLoginError
          : typeof typedFirstLoginError === 'undefined'
            ? 'ra.auth.sign_in_error'
            : typeof typedFirstLoginError === 'object'
              ? (getDeep(['response', 'error'], typedFirstLoginError) as string)
              : 'Error',
        { type: 'warning' },
      );
    }
  }, [authData, firstLoginError, notify]);

  const onClick = () => {
    setError('');
    setLoading(true);

    if (twoFa && code) {
      login({
        code,
        token: twoFaToken,
        email,
        password,
        grecaptcha: recaptchaToken,
      })
        .then(() => {
          setLoading(false);
        })
        .catch((er) => {
          setLoading(false);
          setError(er.message);
        });
    } else {
      setError('');
      setLoading(true);
      doLogin();
    }
  };

  const clickCreate2Fa = () => {
    sendCreate2Fa();
  };

  if (create2Fa) {

    return (
      <>
        {error && <p css={classes.errorMessage}>{error}</p>}
        <Create2FaForm
          methods={methods}
          control={control}
          AuthSaveButton={
            <AuthSaveButton
              label="Verify code"
              loading={create2FaLoading || !recaptchaToken}
              disabled={
                !isValid ||
                create2FaLoading ||
                create2FaData?.status === 'success'
              }
              onClick={handleSubmit(clickCreate2Fa)}
            />
          }
        />
      </>
    );
  }

  if (twoFa && twoFaToken) {
    return (
      <>
        {error && <p css={classes.errorMessage}>{error}</p>}
        <LoginForm2Fa
          methods={methods}
          control={control}
          AuthSaveButton={
            <AuthSaveButton
              label="Verify code"
              loading={loading || !recaptchaToken}
              disabled={!isValid}
              onClick={handleSubmit(onClick)}
            />
          }
        />
      </>
    );
  } else {
    return (
      <>
        {error && <p css={classes.errorMessage}>{error}</p>}
        <LoginFormUsername
          methods={methods}
          control={control}
          AuthSaveButton={
            <AuthSaveButton
              loading={loading || !recaptchaToken}
              disabled={!isValid}
              onClick={handleSubmit(onClick)}
            />
          }
        />
      </>
    );
  }
};
