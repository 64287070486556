import { css } from '@emotion/react';
import { CardActions } from '@mui/material';
import { ReactElement } from 'react';
import {
  Control,
  FieldValues,
  FormProvider,
  UseFormReturn,
} from 'react-hook-form';

import { FormInputText } from './FormInputText';

const classes = {
  form: css`
    padding: 0 1em 1em 1em;
    display: grid;
    grid-row-gap: 20px;
    padding: 20px;
    margin: 10px 40px;
  `,
  input: css`
    margin-top: 1em;
  `,
  button: css`
    width: 100%;
  `,
  icon: () => css`
    margin-right: 8px;
  `,
  warningMessage: css`
    width: 400px;
    padding: 50px;
  `,
};

interface Props {
  control: Control<FieldValues> | undefined;
  AuthSaveButton: ReactElement;
  methods: FieldValues | undefined;
}

export const LoginForm2Fa = ({ control, AuthSaveButton, methods }: Props) => {
  return (
    <FormProvider {...(methods as UseFormReturn<FieldValues>)}>
      <form noValidate>
        <div css={classes.form}>
          <FormInputText
            autoFocus={true}
            defaultValue={''}
            name="code"
            label="Code"
            control={control}
            required
            rules={{
              required: true,
            }}
          />
        </div>

        <CardActions>{AuthSaveButton}</CardActions>
      </form>
      <div css={classes.warningMessage}>
        <p>
          <strong>Attention:</strong> You are entering a secure and sensitive
          area.
        </p>
        <p>
          Unauthorized access to this system is prohibited. Please ensure that
          your login credentials are kept confidential.
        </p>
        <p>
          Do not share your account information or password with anyone. Only
          authorized personnel are allowed access.
        </p>
        <p>
          Furthermore, sharing any information obtained from this system with
          unauthorized individuals or third parties is strictly forbidden.
        </p>
      </div>
    </FormProvider>
  );
};
