import type { } from '@mui/material/SvgIcon';
import amber from '@mui/material/colors/amber';
import blue from '@mui/material/colors/blue';
import deepOrange from '@mui/material/colors/deepOrange';
import deepPurple from '@mui/material/colors/deepPurple';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import pink from '@mui/material/colors/pink';
import purple from '@mui/material/colors/purple';
import red from '@mui/material/colors/red';
import { Theme as MaterialUITheme, ThemeOptions, createTheme } from '@mui/material/styles';


// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module "@emotion/react" {
  export interface Theme extends MaterialUITheme { }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    child: true;
    finance: true;
    human: true;
    school: true;
    erste: true;
    blue: true;
    project: true;
    donation: true;

  }
}

declare module '@mui/material/styles' {


  interface Palette {
    main?: string | object;
    type: string;

    pink: Color;
    blue: Color;
    red: Color;
    deepOrange: Color;
    deepPurple: Color;
    green: Color;
    purple: Color;
    amber: Color;
    celeste: Color;
    eggplant: Color;
    jet: Color;
    raspberry: Color;
    vermilion: Color;
    papaya_whip: Color;
    ash_gray: Color;
    child: Palette['primary'];
    finance: Palette['primary'];
    human: Palette['primary'];
    school: Palette['primary'];
    erste: Palette['primary'];
    project: Palette['primary'];
    donation: Palette['primary'];
  }

  interface PaletteOptions {
    main?: string | object;
    type: string;

    pink: Color;
    blue: Color;
    red: Color;
    deepOrange: Color;
    deepPurple: Color;
    green: Color;
    purple: Color;
    amber: Color;
    celeste: Color;
    eggplant: Color;
    jet: Color;
    raspberry: Color;
    vermilion: Color;
    papaya_whip: Color;
    ash_gray: Color;
    deepBlue: Color;
    child: PaletteOptions['primary'];
    finance: PaletteOptions['primary'];
    human: PaletteOptions['primary'];
    school: PaletteOptions['primary'];
    erste: PaletteOptions['primary'];
    project: PaletteOptions['primary'];
    donation: PaletteOptions['primary'];

  }

  interface Color {
    main?: string;
    light?: string;
    dark?: string;
    contrastText?: string;
    100?: string;
    200?: string;
    300?: string;
    400?: string;
    500?: string;
    600?: string;
    700?: string;
    800?: string;
    900?: string;
    A100?: string;
    A200?: string;
    A400?: string;
    A700?: string;
  }

  // export interface ThemeOptions {
  //   overrides?: object;
  //   spacing: SpacingOptions;
  // }
}



export const themeOptions: ThemeOptions = {
  components: {
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === 'primary') && {
            // color: '#0c71ba',
            '&:hover': {
              // color: '#0f87de',
              textDecoration: 'underline'
            }
          }
        })
      }
    }
  },

  palette: {
    type: 'light',
    // primary: {
    //   main: '#7A00F5',
    //   contrastText: 'rgba(255,255,255,0.87)',
    //   100: '#180031',
    //   200: '#310062',
    //   300: '#490093',
    //   400: '#6200c4',
    //   500: '#7a00f5',
    //   600: '#952bff',
    //   700: '#af60ff',
    //   800: '#ca95ff',
    //   900: '#e4caff'
    // },
    // secondary: {
    //   main: '#FF5C6C',
    //   contrastText: 'rgba(255,255,255,0.87)',
    //   100: '#450007',
    //   200: '#8b000e',
    //   300: '#d00015',
    //   400: '#ff162e',
    //   500: '#ff5c6c',
    //   600: '#ff7c89',
    //   700: '#ff9da7',
    //   800: '#ffbec4',
    //   900: '#ffdee2'
    // },





    // primary: {
    //   main: '#4CB8C4', // Teal
    //   contrastText: '#FFFFFF',
    //   100: '#D6F4F6',
    //   200: '#ADEAEA',
    //   300: '#84E0DE',
    //   400: '#5BDCD9',
    //   500: '#4CB8C4',
    //   600: '#43A2AE',
    //   700: '#3A8C98',
    //   800: '#317682',
    //   900: '#285F6C'
    // },
    // secondary: {
    //   main: '#F0544F', // vermilion
    //   contrastText: '#FFFFFF',
    //   100: '#3b0705',
    //   200: '#760e0a',
    //   300: '#b1150f',
    //   400: '#eb1d16',
    //   500: '#f0544f',
    //   600: '#f37874',
    //   700: '#f69a97',
    //   800: '#f9bcba',
    //   900: '#fcdddc'
    // },


    child: {
      main: '#ff4d4d', // Coral red
      light: '#ff6c6c', // Lighter shade of coral
      dark: '#c62828', // Darker shade of coral
      contrastText: '#ffffff', // White for good contrast
    },
    finance: {
      main: '#2e8b57', // Forest green
      light: '#3cb371', // Pale turquoise
      dark: '#054932', // Deep forest green
      contrastText: '#ffffff', // White for good contrast
    },
    school: {
      main: '#FF5722', // Orange
      light: '#ffebeb', // Lighter shade of orange
      dark: '#c7430e', // Darker shade of orange
      contrastText: '#ffffff', // White for good contrast
    },
    human: {
      main: '#795548', // Warm brown
      light: '#d7c0b3', // Lighter shade of brown
      dark: '#53372d', // Darker shade of brown
      contrastText: '#ffffff', // White for good contrast
    },
    erste: {
      main: "#00497b",
      light: "#91d0e7",
      dark: "#002f55",
      contrastText: "#ffffff",
    },
    project: {
      main: "#FFA000", // Orange
      light: "#FFEBE9", // Lighter, desaturated orange
      dark: "#C67C00", // Darker, richer orange
      contrastText: "#ffffff",
    },
    donation: {
      main: "#2196F3", // Vibrant blue
      light: "#75CCFF", // Lighter, more pastel blue
      dark: "#098BF3", // Darker, richer blue
      contrastText: "#ffffff",
    },



    primary: {
      main: "#eb7c14", // Light peach
      light: "#f2ac6b", // Light peach
      // light: "#ffeedd", // Lighter shade of light peach
      dark: "#e69d60", // Darker shade of light peach
      contrastText: "#000000", // Bl
    },

    secondary: {
      main: '#ca391b', // Deep red
      light: '#e26145', // Lighter shade of red
      dark: '#9a260c', // Darker shade of red
      contrastText: '#ffffff',
    },

    deepBlue: {
      main: '#074978', // Deep teal
      contrastText: '#FFFFFF',
      100: '#E6F0F2',
      200: '#CEDCE8',
      300: '#B3C8CE',
      400: '#98B3BA',
      500: '#074978',
      600: '#06436D',
      700: '#053C62',
      800: '#043557',
      900: '#032E4C',
    },




    background: {
      default: '#FFFCF9',
      paper: '#fffaf9',
    },
    'celeste': {
      main: '#b1ede8',
      100: '#0f433f',
      200: '#1f877e',
      300: '#2ecabd',
      400: '#6dded4',
      500: '#b1ede8',
      600: '#c0f1ed',
      700: '#d0f4f1',
      800: '#dff8f6',
      900: '#effbfa'
    },
    'eggplant': {
      main: '#6d435a',
      100: '#160e12',
      200: '#2c1b25',
      300: '#422937',
      400: '#593649',
      500: '#6d435a',
      600: '#985d7d',
      700: '#b3849e',
      800: '#cdadbe',
      900: '#e6d6df'
    },


    'jet': {
      main: '#3a3335',
      100: '#0b0a0a',
      200: '#171415',
      300: '#221e1f',
      400: '#2d282a',
      500: '#3a3335',
      600: '#63585b',
      700: '#8d7f83',
      800: '#b3aaac',
      900: '#d9d4d6'
    }, 'raspberry': {
      main: '#d81e5b',
      100: '#2b0612',
      200: '#560c25',
      300: '#811237',
      400: '#ac1849',
      500: '#d81e5b',
      600: '#e6447a',
      700: '#ec739b',
      800: '#f2a2bd',
      900: '#f9d0de'
    }, 'vermilion': {
      main: '#f0544f',
      100: '#3b0705',
      200: '#760e0a',
      300: '#b1150f',
      400: '#eb1d16',
      500: '#f0544f',
      600: '#f37874',
      700: '#f69a97',
      800: '#f9bcba',
      900: '#fcdddc'
    }, 'papaya_whip': {
      main: '#fdf0d5',
      100: '#593c04',
      200: '#b17908',
      300: '#f5ae22',
      400: '#f9cf7b',
      500: '#fdf0d5',
      600: '#fdf2dc',
      700: '#fef5e5',
      800: '#fef9ed',
      900: '#fffcf6'
    }, 'ash_gray': {
      main: '#c6d8d3',
      100: '#21312d',
      200: '#436259',
      300: '#649386',
      400: '#94b6ad',
      500: '#c6d8d3',
      600: '#d1e0db',
      700: '#dce7e4',
      800: '#e8efed',
      900: '#f3f7f6'
    },
    grey: grey,
    pink: pink,
    blue: blue,
    red: red,
    deepOrange: deepOrange,
    deepPurple: deepPurple,
    green: green,
    purple: purple,
    amber: amber,
  },
};

export default createTheme(themeOptions)



// export default createTheme({
//   palette: {
//     primary: blue,
//     secondary: pink,
//     error: red,
//     background: {
//       paper: '#FCFCF9',
//       default: '#F4F3EF',
//     },
//     main: blue,
//     type: 'light',
//     pink: pink,
//     blue: blue,
//     red: red,
//     deepOrange: deepOrange,
//     deepPurple: deepPurple,
//     green: green,
//     grey: grey,
//     purple: purple,
//     amber: amber,
//   },
//   overrides: {
//     MuiAppBar: {
//       colorPrimary: {
//         color: 'white',
//       },
//       positionAbsolute: {
//         boxShadow:
//           '0px 2px 3px -1px rgb(0 0 0 / 20%), 0px 3px 3px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)   ',
//       },
//     },
//     MuiTypography: {},

//     RaMenuItemLink: {
//       root: {
//         borderLeft: '3px solid #fff',
//       },
//       active: {
//         borderLeft: '4px solid #f50057',
//       },
//     },
//   }
// });
