import { ReactNode } from 'react';
import { Resource } from 'react-admin';

import { toPascalCase } from '../../Helpers/Helpers';
import { Permissions } from '../../Types/types';
import * as Resources from '../../generatedResourceImports';
import menuList, { MenuProp } from '../Menu/menuList';

interface ResourceMap {
  [key: string]: Resources.DynamicComponent | undefined;
}

export function checkIfExportExists(exportName: string) {
  return exportName in Resources;
}

export const GetResourcesFromMenuList = (
  permissions: Permissions,
): ReactNode[] | [] => {
  const can = (name: string, type: string): boolean | null => {
    return (
      permissions &&
      (permissions.hasPermission((name + '_' + type).toLowerCase()) ||
        permissions.hasRole('enterprise'))
    );
  };

  const resourceComponents = [];

  const getResourceType = (
    item: MenuProp,
    type: 'List' | 'Edit' | 'Create' | 'Show',
  ): Resources.DynamicComponent | undefined => {
    const name = toPascalCase(item.name);
    const resourceName = name + type;

    if (checkIfExportExists(resourceName) && can(item.name, type)) {
      const resourcesMap = Resources as ResourceMap;

      return resourcesMap[resourceName];
    }

    return undefined;
  };

  const resources: MenuProp[] = menuList
    .filter(
      (item: MenuProp) => item.type === 'resource' || item.type === 'group',
    )
    .flatMap(
      (item: MenuProp) =>
        item.type === 'group'
          ? item.resources?.filter((r) => r.type === 'resource') ?? [] // Use default empty array if item.resources is undefined
          : [item], // Wrap non-group items into an array to maintain consistent type
    )
    .filter(
      (item: MenuProp | undefined): item is MenuProp => item !== undefined,
    );

  resourceComponents.push(
    ...resources.map((item, index) => (
      <Resource
        key={item.name.toLowerCase() || index}
        name={item.name.toLowerCase()}
        list={getResourceType(item, 'List')}
        edit={getResourceType(item, 'Edit')}
        create={getResourceType(item, 'Create')}
        show={getResourceType(item, 'Show')}
      />
    )),
  );

  return resourceComponents;
};
